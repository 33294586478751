import React from "react";
import { Link } from "gatsby";

import SiteLayout from "../components/SiteLayout";
import Seo from "../components/Seo";
import Block from "../components/Block";

import style from "../styles/404.module.sass";

const NotFoundPage = ({ location }) => (
    <SiteLayout location={{ location }} is404={true}>
        <Seo title="404 Někde se asi stala chyba" />

        <Block>
            <div className={style.container}>
                <h1>404</h1>
                <h2>Někde se asi stala chyba</h2>
                <p>
                    Požadovanou stránku se nám nepodařilo najít. Zkontrolujte
                    prosím zadanou URL nebo{" "}
                    <Link href="/">pokračujte na úvodní stránku</Link>.
                </p>
            </div>
        </Block>
    </SiteLayout>
);

export default NotFoundPage;
